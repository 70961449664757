export default {
	appearFrom: 'xyz-appear-from',
	appearActive: 'xyz-appear',
	appearTo: 'xyz-appear-to',
	inFrom: 'xyz-in-from',
	inActive: 'xyz-in',
	inTo: 'xyz-in-to',
	outFrom: 'xyz-out-from',
	outActive: 'xyz-out',
	outTo: 'xyz-out-to',
}
